import * as React from "react";
import { graphql } from "gatsby";
import type { HeadFC } from "gatsby";
import useCookieState from "../hooks/useCookieState";
import usePageData from "../hooks/usePageData";
import { PropsType } from "../hooks/propsType";
import Seo from "../components/SEO";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import { transformedData } from "../utils/functions";
import "../assets/scss/index.scss";

const Page: React.FC<PropsType> = ({ data, pageContext }) => {
  const [compareList, setCompareList] = useCookieState(
    "compare",
    JSON.stringify([])
  );
  const [openModal, setOpenModal] = React.useState(false);

  const { settings, products, productsMeta, curentPage } = usePageData(
    data,
    pageContext
  );

  return (
    <div className="tf">
      <Header
        settings={transformedData(settings?.header)}
        settingsFooter={transformedData(settings?.footer)}
        products={products}
        compareList={compareList}
        setCompareList={setCompareList}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <Hero
        settings={transformedData(curentPage?.metadata)}
        descriptionShort={false}
      />
      <section>
        <div className="container">
          <p>Page Under Construction</p>
        </div>
      </section>

      <Footer settings={transformedData(settings?.footer)} />
    </div>
  );
};

export const Head: HeadFC<PropsType> = ({ data, pageContext }) => {
  const { curentPage } = usePageData(data, pageContext);
  const metaData = transformedData(curentPage?.metadata);
  return (
    <Seo title={metaData?.seoTitle} description={metaData?.seoDescription} />
  );
};

export const query = graphql`
  query {
    allMdx(sort: { frontmatter: { date: DESC } }) {
      nodes {
        ...MdxFields
      }
    }
  }
`;

export default Page;
